import { setDefaultOptions } from 'date-fns';
import * as DateFnsLocales from 'date-fns/locale';
import { useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useLocalStorage } from 'usehooks-ts';

import de from './de.json';
import en from './en.json';
import fr from './fr.json';
import it from './it.json';
import zhCN from './zh_CN.json';

import { getCookie, setCookie } from '~/utils/cookies';
import { flattenKeysToIcuPlural } from '~/utils/json';

enum LocaleCode {
  de = 'de',
  en_GB = 'en-GB',
  fr = 'fr',
  it = 'it',
  zh_CN = 'zh-CN',
}

type Locales = {
  [key: string]: {
    locale: string;
    name: string;
    messages: Record<string, string>;
  };
};

const DEFAULT_LOCALE = 'en-GB';

const LOCALES: Locales = {
  'en-GB': {
    locale: 'en-GB',
    name: 'English',
    messages: flattenKeysToIcuPlural(en),
  },
  fr: {
    locale: 'fr',
    name: 'Français',
    messages: flattenKeysToIcuPlural(fr),
  },
  it: {
    locale: 'it',
    name: 'Italiano',
    messages: flattenKeysToIcuPlural(it),
  },
  de: {
    locale: 'de',
    name: 'Deutsch',
    messages: flattenKeysToIcuPlural(de),
  },
  'zh-CN': {
    locale: 'zh-CN',
    name: '简体中文',
    messages: flattenKeysToIcuPlural(zhCN),
  },
} as const;

function useLocales() {
  const language = getCookie('site_language') as LocaleCode | undefined;
  const [locale, setLocale] = useLocalStorage('LOCALE', language || DEFAULT_LOCALE);
  const messages = useMemo(() => LOCALES[locale].messages, [locale]);

  useEffect(() => {
    setLocale(language || DEFAULT_LOCALE);
  }, [setLocale, language]);

  function setDateLocale(localeCode: string = locale) {
    const translationsKey = localeCode.replace('-', '') as keyof typeof DateFnsLocales;
    setDefaultOptions({ locale: DateFnsLocales[translationsKey] });
  }

  function updateLocale(locale: LocaleCode) {
    setLocale(locale);
    setDateLocale(locale);
    setCookie('site_language', locale);
  }

  const localeOptions = [
    {
      id: LocaleCode.de,
      value: LocaleCode.de,
      label: 'Deutsch',
    },
    {
      id: LocaleCode.en_GB,
      value: LocaleCode.en_GB,
      label: 'English',
    },
    {
      id: LocaleCode.fr,
      value: LocaleCode.fr,
      label: 'Français',
    },
    {
      id: LocaleCode.it,
      value: LocaleCode.it,
      label: 'Italiano',
    },
    {
      id: LocaleCode.zh_CN,
      value: LocaleCode.zh_CN,
      label: '简体中文',
    },
  ];

  return { locale, localeOptions, messages, updateLocale, setDateLocale };
}

function WithIntlProvider({ children }: { children: React.ReactNode }) {
  const { locale, messages } = useLocales();

  return (
    <IntlProvider defaultLocale={DEFAULT_LOCALE} locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}

export { useLocales, WithIntlProvider, LocaleCode, DEFAULT_LOCALE, LOCALES };
